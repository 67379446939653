import Link from 'next/link'
import { LegacyRef } from 'react'
import { BreadcrumbItem } from '../Breadcrumb/breadcrumb.model'
import styles from '@/components/Breadcrumb/breadcrumb.module.scss'

/*
NOTE: 
This component is a copy of @usb-shield/react-breadcrumb but uses NEXT.js <Link> component instead of regular <anchor> tag
*/
const EDPBreadcrumb = ({
  forwardedRef = null,
  id = '',
  addClasses = '',
  breadcrumbs,
  dataTestId = '',
}: {
  forwardedRef?: LegacyRef<HTMLElement>
  id?: string
  addClasses?: string
  breadcrumbs: BreadcrumbItem[]
  dataTestId?: string
}) => {
  return (
    <nav
      ref={forwardedRef}
      id={'breadcrumb-element'}
      aria-label="breadcrumb"
      className={`usb-breadcrumb ${addClasses}`}
      data-testid={`usb-breadcrumb-${dataTestId}`}
    >
      <ol className={styles.breadcrumb_ol}>
        {breadcrumbs.map((breadcrumb: BreadcrumbItem, index: number) => {
          if (index === breadcrumbs.length - 1) {
            return (
              <li
                key={breadcrumb.id}
                className={
                  'usb-breadcrumb--page-title ' + styles.breadcrumbTitle
                }
                aria-current="page"
              >
                {breadcrumb.text}
              </li>
            )
          } else {
            if (breadcrumb.href && breadcrumb.text) {
              return (
                <li
                  key={breadcrumb.id}
                  className={
                    'usb-breadcrumb--page-title ' + styles.breadcrumbTitle
                  }
                >
                  <Link href={breadcrumb.href}>{breadcrumb.text}</Link>
                </li>
              )
            }
          }
        })}
      </ol>
    </nav>
  )
}

export default EDPBreadcrumb
