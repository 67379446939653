import Head from 'next/head'
import Footer from './footer/Footer'
import Header from './header/Header'
import styles from '@/layout/pageLayout.module.scss'
import { toast, ToastBar, Toaster } from 'react-hot-toast'
import { SkipLinks } from '@/components/SkipLinks/SkipLinks'
import Tealium from '@/modules/tealium/Tealium'
import CookiesBanner from '@/layout/cookiesBanner/CookiesBanner'
import { useCookies } from 'react-cookie'
import {getTitleFromRoute, titleTagSuffix} from '@/utils/dom-elements'
import {  useEffect, useState } from 'react'
import { isCookieExists, deleteCookie } from '@/utils/cookies/cookies'

const PageLayout = ({ children }: any) => {
  const isElavon: boolean = (process.env.CONTENT_BRAND === 'elavon')
  
  const headerData = children?.props?.headerData
  const footerData = children?.props?.footerData
  const cookiesData = children?.props?.cookiesData?.data
  
  let title = children?.props?.pageData?.metaData?.pageTitle
    ? `${children.props.pageData.metaData.pageTitle} | ${titleTagSuffix}`
    : `${getTitleFromRoute()}`

  if (isElavon) {
    title = children?.props?.pageData?.metaData?.name && children?.props?.pageData?.metaData?.pageTitle
      ? `${children.props.pageData.metaData.name} | ${children.props.pageData.metaData.pageTitle}`
      : `${getTitleFromRoute()}`
  }
  
  const [cookies] = useCookies()
  const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(false)

  useEffect(() => {
    const userCookies = cookies['acceptCookies'];

    if(userCookies) {
      if (isCookieExists(userCookies, "Performance/analytics cookies")) {
        setIsAnalyticsEnabled(true)

      } else {
        setIsAnalyticsEnabled(false)
        deleteCookie('utag_main')
      }
    }
  }, [isAnalyticsEnabled]);
  
  const renderTealium = () => {
    
    return <Tealium {...children?.props} analyticsEnabled={isAnalyticsEnabled}/>

  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta
          name="description"
          content="The API Dev Portal provides comprehensive documentation for use by API developers."
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      {/* Header */}
      <header className={styles.mainHeader}>
        {/* Skip links */}
        <SkipLinks />
        <Header {...headerData}></Header>
      </header>

      {/* Page content */}
      <main id="content" className={styles.mainContentStyling} tabIndex={-1}>
        <Toaster
          position="bottom-center"
          containerStyle={{
            bottom: '16px',
          }}
          toastOptions={{
            success: {
              className: 'success-toast',
              duration: 5000,
              style: {
                background: '#007D1E',
                borderRadius: '8px',
                color: 'white',
              },
              iconTheme: {
                primary: 'white',
                secondary: '#007D1E',
              },
            },
            error: {
              className: 'error-toast',
              duration: 5000,
              style: {
                background: '#CF2A36',
                borderRadius: '8px',
                color: 'white',
              },
              iconTheme: {
                primary: 'white',
                secondary: '#CF2A36',
              },
            },
          }}
        >
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <div className="success-toast-container">
                  <div className="success-toast-content">
                    {icon}
                    {message}
                  </div>
                  {t.type !== 'loading' && (
                    <div
                      className={t.className + '-icon'}
                      onClick={() => toast.dismiss(t.id)}
                      aria-label="Close"
                    >
                      <img src="/close.svg" alt="Close" />
                    </div>
                  )}
                </div>
              )}
            </ToastBar>
          )}
        </Toaster>
        {children}
      </main>
      {!cookies['acceptCookies'] && (
        <div className={styles.acceptCookiesStyle}>
          <CookiesBanner {...cookiesData}></CookiesBanner>
        </div>
      )}
      {/* Footer */}
      <footer className={styles.footer}>
        <Footer {...footerData}></Footer>
      </footer>
      {renderTealium()}
    </>
  )
}

const displayPage = (Component: any) => {
  const wrappedComponent = (props: any) => {
    let finalProps = JSON.parse(JSON.stringify(props))

    Object.preventExtensions(finalProps)
    return (
      // <LocaleProvider>
        <PageLayout {...finalProps}>
          <Component {...finalProps} />
        </PageLayout>
      // </LocaleProvider>
    )
  }

  return wrappedComponent
}

export default displayPage