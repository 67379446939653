// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import * as Tealium from '@/modules/tealium/Tealium'
import '@usb-shield/react-table/dist/library/styles/index.css'
import { USBGrid, USBColumn } from '@usb-shield/react-grid'
import styles from './custom404.module.scss'
import USBLink from '@usb-shield/react-link'
import { useGetUserInfoClientSide } from '@/utils/user/user.util'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { getSiteLinkLabel } from '@/utils/500-404'

export const colOptionContact = {
  span: null,
  spans: {
    xlarge: 6,
    large: 6,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 2,
    medium: 0,
    small: 0,
  },
  display: 'block',
  padding: 'zero',
  align: 'top',
  justify: 'left',
}

export const colOptionContactWithoutOffset = {
  ...colOptionContact,
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
}
const USBank404 = ({ className }: { className?: string }) => {
  let status = useGetUserInfoClientSide().status,
    userInfo = useGetUserInfoClientSide().userInfo,
    env = useGetUserInfoClientSide().env,
    cdnScripts = Tealium.getCdnScripts({ env: env, analyticsEnabled: true })

  const [initialStatus, setInitialStatus] = useState(status)
  let interval = useRef(null)
  const router = useRouter()

  useEffect(() => {
    if (initialStatus !== 'complete') {
      interval.current = setInterval(() => {
        setInitialStatus(document.readyState)
      }, 1000)
    } else {
      let reportingParams = {
        env: env,
        userInfo: userInfo,
        status: status,
      }

      if (!router.isReady) return
      Tealium.report404(reportingParams, router)
    }

    return () => clearInterval(interval.current)
  }, [initialStatus, router.isReady])

  return (
    <>
      <Head>
        <title>404 Error | Developer Portal</title>
      </Head>
      <div className={styles.mainContainer}>
        <USBGrid padding="normal" addClasses={styles.gridWrapper}>
          <USBColumn
            layoutOpts={
              className ? colOptionContactWithoutOffset : colOptionContact
            }
          >
            <div className={styles.header}>
              <h1>404 page not found</h1>
            </div>
            <div className={styles.shortText}>
              <p>Don't worry, we'll get you back on track.</p>
              <p>
                The page you're looking for no longer exists. Try one of our
                resources instead.
              </p>
            </div>
            <div className={styles.pageLinks}>
              <ul>
                <li>
                  <USBLink linkType="link" href={'/'}>
                    {getSiteLinkLabel()}
                  </USBLink>
                </li>
                {process.env.FEATURE_DEVELOPER_DASHBOARD !== 'false' && (
                  <li>
                    <USBLink linkType="link" href={'/dashboard'}>
                      My dashboard
                    </USBLink>
                  </li>
                )}
                {process.env.DISABLE_ALL_PRODUCTS_PAGE?.toLowerCase() !== 'true' && (
                  <li>
                    <USBLink linkType="link" href={'/products'}>
                      API products
                    </USBLink>
                  </li>
                )}
                {process.env.SITE_BRAND === 'usbank' && (
                  <li>
                    <USBLink linkType="link" href={'/solution-areas'}>
                      Solution areas
                    </USBLink>
                  </li>
                )}

                <li>
                  <USBLink
                    linkType="link"
                    href={'/getting-started-with-our-apis'}
                  >
                    Getting started with our APIs
                  </USBLink>
                </li>
              </ul>
            </div>
          </USBColumn>
        </USBGrid>
        {cdnScripts}
      </div>
    </>
  )
}

export default USBank404
